/*
This file is used to contain all layout imports.
Files inside this folder should contain all styles relating
to layouts.
*/
//Import layout files

main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container{
    width: calc(100% - 96px);
    max-width: $max-width;
}

section{
    position: relative;
    width: calc(100% - 96px);

}

#stories{
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer{
    width: calc(100% - 96px);
}

@media (max-width: 48em) {
    .container{
        width: calc(100% - 48px);
        max-width: $max-width;
    }

    #stories{
        width: 100%;
        max-width: $max-width;
    }
    
    footer{
        width: calc(100% - 48px);
    }
}