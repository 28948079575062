$inter: "Inter";
$woodward: "Woodward";

$max-width:1400px;

$breakpoint-xsmall: 320px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-extra-large: 1300px;

$text-primary: #FFFFFF;
$text-primary-inverse: #000000;
$text-accent: #F79D4A;