// Mixins
@mixin fluid-typography($minFont, $maxFont, $minBreakpoint, $maxBreakpoint) {
  /* Define variable for media query */
  $maxLessOne: calc($maxBreakpoint - 1);

  /* Define variable for fallback */
  $avg: calc(($maxFont + $minFont) / 2);

  /* Base font size */
  font-size: #{$minFont}px;

  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    /* Adds a fallback for unsupported browsers */
    font-size: #{$avg}px;

    /* The fluid typography magic 🌟  */
    font-size: calc(
      #{$minFont}px + (#{$maxFont} - #{$minFont}) *
        (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint})
    );
  }

  @media (min-width: #{$maxBreakpoint}px) {
    font-size: #{$maxFont}px;
  }
}

@mixin min-width($breakpoint) {
  @if ($breakpoint == xsmall) {
    @media (min-width: $breakpoint-xsmall) {
      @content;
    }
  } @else if ($breakpoint == small) {
    @media (min-width: $breakpoint-small) {
      @content;
    }
  } @else if ($breakpoint == medium) {
    @media (min-width: $breakpoint-medium) {
      @content;
    }
  } @else if ($breakpoint == large) {
    @media (min-width: $breakpoint-large) {
      @content;
    }
  } @else if ($breakpoint == xlarge) {
    @media (min-width: $breakpoint-extra-large) {
      @content;
    }
  } @else {
    @error "UNKNOWN MEDIA BREAKPOINT #{$breakpoint}";
  }
}
