body {
    font-family: $inter, sans-serif;
    font-weight: 400;
    color: $text-primary;
}

h1 {
    font-family: $woodward;
    font-weight: 400;
    @include fluid-typography(48,90,400,1500);
    color: $text-accent;
}

h3 {
    font-family: $woodward;
    font-weight: 400;
    @include fluid-typography(32,60,400,1500);
    color: $text-primary-inverse;
}


p {
    @include fluid-typography(16,25,400,1500);
}

a {
    font-weight: 500;
    color: $text-accent;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
}

#stories{
    h1{
        color: $text-primary-inverse;
    }
}

