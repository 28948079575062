@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");
  font-display: swap;
}

@font-face {
  font-family: Woodward;
  font-weight: 400;
  src: url("Woodward-Regular.de0865e2.woff") format("woff");
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

h1 {
  color: #f79d4a;
  font-family: Woodward;
  font-size: 48px;
  font-weight: 400;
}

@media (min-width: 400px) and (max-width: 1499px) {
  h1 {
    font-size: calc(3.81818vw + 32.7273px);
  }
}

@media (min-width: 1500px) {
  h1 {
    font-size: 90px;
  }
}

h3 {
  color: #000;
  font-family: Woodward;
  font-size: 32px;
  font-weight: 400;
}

@media (min-width: 400px) and (max-width: 1499px) {
  h3 {
    font-size: calc(2.54545vw + 21.8182px);
  }
}

@media (min-width: 1500px) {
  h3 {
    font-size: 60px;
  }
}

p {
  font-size: 16px;
}

@media (min-width: 400px) and (max-width: 1499px) {
  p {
    font-size: calc(.818182vw + 12.7273px);
  }
}

@media (min-width: 1500px) {
  p {
    font-size: 25px;
  }
}

a {
  color: #f79d4a;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#stories h1 {
  color: #000;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #000;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.swiper-slide #emmy {
  object-fit: contain;
}

.swiper-slide img {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  width: 100%;
  height: 100%;
  max-height: 220px;
  object-fit: cover;
  display: block;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.lightbox {
  position: absolute;
  left: -999em;
}

.lightbox:target {
  position: absolute;
  inset: 0;
}

.lightbox:target .close a {
  z-index: 1;
  background: #000000e6;
  position: absolute;
  inset: 0;
}

.close span {
  color: #fff;
  text-indent: 0;
  font-size: 2em;
  position: absolute;
  top: .5em;
  right: .5em;
}

.close {
  text-indent: -999em;
}

.lightbox:target div {
  z-index: 99;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.w640 {
  width: 640px;
  margin-left: -320px;
}

.h386 {
  height: 386px;
  margin-top: -193px;
}

.scroll {
  padding: 0 1em;
  overflow-y: scroll;
}

.boxfocus {
  position: absolute;
  inset: 0;
}

.boxfocus div {
  z-index: 99;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.boxfocus .close a {
  z-index: 1;
  position: absolute;
  inset: 0;
}

main {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.container {
  width: calc(100% - 96px);
  max-width: 1400px;
}

section {
  width: calc(100% - 96px);
  position: relative;
}

#stories {
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  display: flex;
}

footer {
  width: calc(100% - 96px);
}

@media (max-width: 48em) {
  .container {
    width: calc(100% - 48px);
    max-width: 1400px;
  }

  #stories {
    width: 100%;
    max-width: 1400px;
  }

  footer {
    width: calc(100% - 48px);
  }
}

.body-large-strong {
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 300px) and (max-width: 1499px) {
  .body-large-strong {
    font-size: calc(.5vw + 16.5px);
  }
}

@media (min-width: 1500px) {
  .body-large-strong {
    font-size: 24px;
  }
}

.body-large {
  font-size: 18px;
}

@media (min-width: 300px) and (max-width: 1499px) {
  .body-large {
    font-size: calc(.5vw + 16.5px);
  }
}

@media (min-width: 1500px) {
  .body-large {
    font-size: 24px;
  }
}

nav {
  width: 100%;
  height: 60px;
  z-index: 3;
  background-color: #000;
  padding: 0;
  font-weight: 600;
  position: fixed;
  box-shadow: 1px 1px 4px #0000001a;
}

nav ul {
  background-color: #000;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

nav li {
  cursor: pointer;
  padding: 24px;
  transition: all .15s ease-in-out;
}

nav li:hover, nav .menu-btn:hover {
  color: #f79d4a;
}

nav li a {
  color: unset;
  font-weight: 600;
  text-decoration: none;
}

nav li a:hover {
  text-decoration: none !important;
}

nav li a:visited {
  color: unset;
}

nav .logo {
  float: left;
  display: block;
}

@media (max-width: 48em) {
  .logo {
    width: 200px;
    margin-top: 6px;
  }
}

nav .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

nav .menu-icon {
  cursor: pointer;
  float: right;
  -webkit-user-select: none;
  user-select: none;
  padding: 28px 20px;
  position: absolute;
  right: 0;
}

nav .menu-icon .navicon {
  height: 2px;
  width: 18px;
  background: #fff;
  transition: background .2s ease-out;
  display: block;
  position: relative;
}

nav .menu-icon .navicon:before, nav .menu-icon .navicon:after {
  content: "";
  height: 100%;
  width: 100%;
  background: #fff;
  transition: all .2s ease-out;
  display: block;
  position: absolute;
}

nav .menu-icon .navicon:before {
  top: 5px;
}

nav .menu-icon .navicon:after {
  top: -5px;
}

nav .menu-btn {
  display: none;
}

nav .menu-btn:checked ~ .menu {
  max-height: -moz-fit-content;
  max-height: fit-content;
}

nav .menu-btn:checked ~ .menu-icon .navicon {
  background: none;
}

nav .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

nav .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before, nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media (min-width: 768px) {
  nav {
    height: 80px;
    padding: 0 48px;
  }

  nav li {
    float: left;
    padding: 20px 24px;
  }

  nav .menu {
    clear: none;
    float: right;
    max-height: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  nav .menu-icon {
    display: none;
  }
}

header {
  width: 100%;
  min-height: 75vh;
  background: url("lp-cover.f65c13e0.webp") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  padding: 48px;
  display: flex;
  position: relative;
}

header .content {
  width: 50%;
  position: relative;
}

header .content h1 {
  margin: 0;
}

header .content #description {
  padding-top: 40px;
}

@media (max-width: 48em) {
  header {
    padding: 0;
  }

  header .content {
    width: 100%;
  }
}

footer {
  height: 100px;
  color: #000;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
  padding-bottom: 40px;
  font-size: 14px;
  display: flex;
}

@media (min-width: 300px) and (max-width: 1499px) {
  footer {
    font-size: calc(.166667vw + 13.5px);
  }
}

@media (min-width: 1500px) {
  footer {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.hide-overflow {
  height: 100vh;
  overflow: hidden;
}

.no-pointer {
  pointer-events: none;
}

.scrim {
  opacity: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.scrim.is-active {
  opacity: 1;
  display: block;
}

.scrim-btn {
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #000c;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar {
  z-index: 2;
  height: 100%;
  width: 100%;
  max-width: 720px;
  color: #000;
  background-color: #fff;
  flex: 1 1 0;
  padding-top: 24px;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  right: -720px;
  overflow: hidden;
  transform: translateX(720px);
}

@media (min-width: 768px) {
  .sidebar {
    width: 720px;
  }
}

.sidebar:before {
  content: "";
  width: 100%;
  height: 100px;
  z-index: 999;
  pointer-events: none;
  background: linear-gradient(#fff0 0%, #fff 88%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.sidebar.is-active {
  transition: all .5s ease-in-out;
  transform: translateX(-720px);
}

.sidebar .close-btn {
  cursor: pointer;
  float: right;
  background: none;
  border: #0000;
  padding-right: 24px;
}

.sidebar h3 {
  margin-bottom: 2rem;
  padding-left: 24px;
}

.sidebar ul {
  padding: 0 24px 24px;
  list-style: none;
}

.sidebar p {
  font-size: 16px;
}

.sidebar .credits-all {
  width: 100%;
  height: 90%;
  padding-bottom: 32px;
  position: relative;
  overflow-y: scroll;
}

.sidebar .credits-all .year-group {
  padding-bottom: 24px;
  display: block;
}

.sidebar .credits-all .year-group .year {
  color: #f79d4a;
  font-weight: 700;
}

.sidebar .credits-all .year-group .credit {
  color: #000000b3;
}

.sidebar .credits-all .year-group .credit .title {
  color: #000;
  font-weight: 500;
}

.sidebar #location {
  padding: 0 24px;
}

.sidebar #map {
  width: 100%;
  height: 300px;
}

.sidebar div {
  margin-bottom: 1rem;
}

.sidebar h4 {
  margin-bottom: .5rem;
}

.sidebar #inquiries {
  padding: 0 24px;
}

.sidebar #inquiries a {
  display: block;
}

.plyr__control--overlaid {
  background: #f79d4a;
}

.plyr--full-ui input[type="range"] {
  color: #f79d4a;
}

.glightbox-container .ginner-container.gvideo-container {
  align-items: center;
}

.swipe-btn {
  width: 48px !important;
  height: 48px !important;
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 999px !important;
}

.swipe-btn:after {
  font-size: 20px;
  font-weight: bold;
}

/*# sourceMappingURL=index.2b3f4ec3.css.map */
