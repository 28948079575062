@charset "UTF-8";

//Abstracts
@import "abstracts/__abstracts-dir";
//Base Styles
@import "base/__base-dir";
//Components
@import "components/__components-dir";
//Layout
@import "layouts/__layouts-dir";


.body-large-strong {
  font-weight: 600;
  @include fluid-typography(18,24,300,1500);
}

.body-large {
  @include fluid-typography(18,24,300,1500);
}

/* nav */

nav {
  background-color: #000000;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 3;
  padding: 0;
  font-weight: 600;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #000000;
}

nav li{
  padding: 24px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

nav li:hover,
nav .menu-btn:hover {
  color: $text-accent;
}
nav li a{
  text-decoration: none;
  color:unset;
  font-weight: 600;

  &:hover{
    text-decoration: none !important;
  }
}

nav li a:visited{
  color:unset;
}

nav .logo {
  display: block;
  float: left;
}


@media (max-width: 48em) {
  .logo{
    width: 200px;
    margin-top: 6px;
  }
}

/* menu */

nav .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

nav .menu-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  user-select: none;
}

nav .menu-icon .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

nav .menu-icon .navicon:before,
nav .menu-icon .navicon:after {
  background: #fff;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

nav .menu-icon .navicon:before {
  top: 5px;
}

nav .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

nav .menu-btn {
  display: none;
}

nav .menu-btn:checked ~ .menu {
  max-height: fit-content;
}

nav .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

nav .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

nav .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@include min-width(medium)  {
  nav{
    padding: 0 48px;
    height: 80px;
  }
  nav li {
    float: left;
    padding: 20px 24px;
  }
  nav .menu {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    clear: none;
    float: right;
    max-height: none;
  }
  nav .menu-icon {
    display: none;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 75vh;
  background: url('../assets/img/lp-cover.webp') no-repeat center center;
  background-size: cover;
  padding: 48px;

  .content {
    position: relative;
    width: 50%;

    h1 {
      margin: 0;
    }

    #description {
      padding-top: 40px;
    }
  }
}

@media (max-width: 48em) {
 header{
  padding: 0;

  .content{
    width: 100%;
  }
 }
}

footer {
  @include fluid-typography(14,16,300,1500);

  height: 100px;
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: $text-primary-inverse;
  text-align: center;

  @include min-width(large) {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
}

.hide-overflow{
  height: 100vh;
  overflow: hidden;
}
.no-pointer{
  pointer-events: none;
}

.scrim{
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height:100%;
  width: 100%;
  transition: all 500ms ease-in-out;

  &.is-active{
    display: block;
    opacity: 1;
  }
}

.scrim-btn{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.8);
  height:100%;
  width: 100%;
  transition: all 500ms ease-in-out;
}

.sidebar{
  position: absolute;
  z-index: 2;
  top: 0;
  right: -720px;
  transform: translateX(720px);
  height: 100%;
  overflow: hidden;
  flex: 1 1 0;
  width: 100%;
  max-width: 720px;
  padding-top: 24px;
  background-color: #FFFFFF;
  color: #000000;
  transition: all 500ms ease-in-out;

  @include min-width(medium) {
    width: 720px;
  }

  &:before {
    content:'';
    width:100%;
    height:100px;    
    z-index: 999;
    position:absolute;
    left:0;
    bottom:0;
    background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 88%);
    pointer-events: none;
  }

  &.is-active{
    transform: translateX(-720px);
    transition: all 500ms ease-in-out;
  }

  .close-btn{
    padding-right: 24px;
    cursor: pointer;
    float:right;
    background: transparent;
    border: transparent;
  }

  h3{
    margin-bottom: 2rem;
    padding-left: 24px;
  }

  ul{
    padding: 0 24px 24px;
    list-style: none;
  }

  p{
    font-size: 16px;
  }

  .credits-all {
    position: relative;
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    padding-bottom: 32px;


    .year-group {
      display: block;
      padding-bottom: 24px;

      .year {
        color: $text-accent;
        font-weight: 700;
      }

      .credit{
        color: rgba($text-primary-inverse, 70%);

        .title{
          font-weight: 500;
          color: $text-primary-inverse;
        }
      }
    }
  }

  #location{
    padding: 0 24px
  }
  #map{
    width:100%;
    height: 300px;
  }

  div{
    margin-bottom: 1rem;
  }

  h4{
    margin-bottom: 0.5rem;
  }

  #inquiries{
    padding: 0 24px;
    a{
      display: block;
    }
  }
}

.plyr__control--overlaid{
  background: $text-accent;
}

.plyr--full-ui input[type=range]{
  color: $text-accent
}

.glightbox-container .ginner-container.gvideo-container {
 align-items: center;
}

.swipe-btn{
  width: 48px !important;
  height: 48px !important;
  border-radius: 999px !important;
  background-color: #FFF !important;
  color:#000 !important;

  &:after{
    font-size: 20px;
    font-weight: bold;
  }
}